class Notification {
    constructor(notification){
            if(!notication){
                return;
            }

            this.notification = notification;

            // Set click event
            this.notification.addEventListener('click', () => this.destroy());

            // Check if DOM Loaded
            document.addEventListener('DOMContentLoaded', () => {
                this.store();
            });

            const getCookieNotif = Cookies.get('px-notif');
            const getCookieNotifText = this.notification.getAttribute('data-content');

            if(!getCookieNotif || getCookieNotifText !== getCookieNotif) {
                this.activate();
            }
    }

    store(){
        const notificationState = localStorage.getItem('notification');
        if(!notificationState){
            this.activate();
        } else {
            this.destroy();
        }
    }

    activate() {
        this.notification.classList.add('notification--active');

        // Remove cookie
        Cookies.remove('px-notif');
    }

    destroy(){
        const that = this;
        localStorage.setItem('notification', 'true');
        that.notification.classList.remove('notification--active');
        that.notification.classList.add('notification--destroy');
        const getCookieNotifContent = that.notification.getAttribute('data-content');

        // Set cookie
        Cookies.set('px-notif', getCookieNotifContent, { expires: 30 });
    }
}

// const notification = new Notification(document.querySelector('.notification'));