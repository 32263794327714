import YouTubePlayer from "youtube-player";

const players = [...document.querySelectorAll(".yt_player")];

players.forEach((player) => {
  const player_id = player.getAttribute("data-youtube-id");
  const playButton = player.closest('.hero')?.querySelector(
    '[data-video-button="play"]'
  );
  const pauseButton = player.closest('.hero')?.querySelector(
    '[data-video-button="pause"]'
  );
  const text = player.closest('.hero')?.querySelector('[data-video-text]');

  const hiddenClass = "is-hidden";

  const pl = YouTubePlayer(player, {
    videoId: player_id,
    playerVars: {
      autoplay: 1,  // Autoplay enabled
      controls: 0,  // No player controls
      loop: 1,      // Loop the video
      fs: 0,        // Disable fullscreen option
      showinfo: 0,  // Hide video information
      rel: 0,       // Don't show related videos
      modestbranding: 1,  // Minimal YouTube branding
      playsinline: 1,     // Play video inline on mobile
      mute: 1,       // Start video muted
    },
  });

  // Mute the player and play on load
  function onPlayerReady(event) {
    pl.mute();
    pl.playVideo();
  }

  pl.on("ready", onPlayerReady); // Add 'ready' event listener

  pl.on('stateChange', (event) => {
    switch (event.data) {
      case -1:
        console.log('Video unstarted');
        break;
      case 0:
        console.log('Video ended');
        break;
      case 1:
        console.log('Video playing');
        playButton?.classList.add(hiddenClass);  // Hide play button when playing
        break;
      case 2:
        console.log('Video paused');
        pauseButton?.classList.add(hiddenClass);  // Hide pause button when paused
        break;
      case 3:
        console.log('Video buffering');
        break;
      case 5:
        console.log('Video cued');
        break;
    }
  });

  // Play the video, and unmute if needed
  function play() {
    pl.playVideo();
    pl.unMute();
    console.log('play');
  }

  // Pause the video
  function pause() {
    pl.pauseVideo();
  }

  // Play button event listener
  playButton?.addEventListener("click", async () => {
    play();
    text.classList.add('invisible');
    playButton?.classList.toggle('invisible');
    pauseButton?.classList.remove('invisible');
  });

  // Pause button event listener
  pauseButton?.addEventListener("click", async () => {
    pause();
    pauseButton?.classList.toggle('invisible');
    playButton?.classList.remove('invisible');
  });

  // IntersectionObserver to auto-play/pause video when in/out of view
  const myObserver = new IntersectionObserver(
    (elements) => {
      if (elements[0].isIntersecting) {
        console.log('In view, play video');
        play();
      } else {
        console.log('Out of view, pause video');
        pause();
      }
    },
    { threshold: 1 }
  );

  // Start observing the player element once the DOM is loaded
  window.addEventListener("DOMContentLoaded", () => {
    myObserver.observe(player);
  });
});