class ScrollDown {
  constructor(parentElement) {
    this.parentElement = parentElement;
    this.bindEvents();
  }

  bindEvents() {
    document.querySelectorAll('[data-scroll-down]').forEach(element => {
      element.addEventListener('click', this.handleScroll.bind(this));
    });
  }

  handleScroll(event) {
    event.preventDefault();

    const nextPane = this.findNextPane(event.target);
    if (nextPane) {
      nextPane.scrollIntoView({ behavior: 'smooth' });
    }
  }

  findNextPane(target) {
    const currentPane = target.closest(this.parentElement);

    if (currentPane) {
      return document.querySelector(`${this.parentElement} + .pane`);
    }

    return;
  }
}

new ScrollDown('.hero-gallery');