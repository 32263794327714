import Swiper from 'swiper/bundle';

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1680,
};

(function () {

    const effects = [
        'slide',
        'fade',
        'cube',
        'coverflow',
        'flip',
        'creative',
        'cards'
    ];

    const swiperSizes = {
        fourth: 4,
        half: 2,
        third: 3,
        full: 1,
    };

    const swiperOptions = {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoHeight: false,
        slideClass: 'swiper__slide',
        createElements: true,
        watchOverflow: true,

        lazy: {
            loadPrevNext: true, // Optionally preload images
        },


        // Navigation arrows
        navigation: {
            nextEl: '.swiper__button--next',
            prevEl: '.swiper__button--prev',
            disabledClass: 'swiper__button--disabled'
        },

        speed: 500,
        spaceBetween: 40,
        effect: effects[0],
        grabCursor: true,

        breakpoints: {
            [`${breakpoints.xs}`]: {
                slidesPerView: 1.2,
                centeredSlides: true,
            },
            [`${breakpoints.lg}`]: {
                slidesPerView: 2,
                centeredSlides: false,
            }
        }
    };

    const swipers = [...document.querySelectorAll('.swiper:not(.swiper--gallery-thumbs):not(.swiper--gallery)')];

    swipers.forEach(swiper => {
        if (swiper.closest(".pane")) {
            if (swiper.classList.contains('swiper--xs')) {
                if (window.matchMedia('(max-width: 992px)')) {
                    swiperOptions.navigation = {
                        nextEl: swiper.closest(".pane").querySelector(".swiper__button--next"),
                        prevEl: swiper.closest(".pane").querySelector(".swiper__button--prev"),
                        disabledClass: "swiper__button--disabled",
                    };
                }
                else {
                    swiperOptions.navigation = {};
                }
            }
            else {
                if (window.matchMedia('(min-width: 992px)')) {
                    swiperOptions.navigation = {
                        nextEl: swiper.closest(".pane").querySelector(".swiper__button--next"),
                        prevEl: swiper.closest(".pane").querySelector(".swiper__button--prev"),
                        disabledClass: "swiper__button--disabled",
                    };
                }
                else {
                    swiperOptions.navigation = {};
                }
            }
        }
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        Object.entries(swiperSizes).forEach(function (swiperSize) {
            const [key, value] = swiperSize;
            if (swiper.classList.contains(`swiper--${key}`)) {
                swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                    slidesPerView: value,
                    centeredSlides: false,
                };
            }
        });

        if (swiper.classList.contains('swiper--posts')) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.2,
                spaceBetween: 20,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3,
                spaceBetween: 40,
            };
        }

        if (swiper.classList.contains('swiper--gallery-thumbs')) {
            swiperOptions.spaceBetween = 14;
            swiperOptions.autoHeight = false;
            swiperOptions.watchSlidesVisibility = true,
                swiperOptions.watchSlidesProgress = true,
                swiperOptions.centeredSlides = false,
                swiperOptions.slideToClickedSlide = false,
                swiperOptions.grabCursor = true;
            swiperOptions.allowTouchMove = true;
            swiperOptions.direction = swiper.getAttribute('data-swiper-direction') || 'horizontal';

            if (swiperOptions.direction === 'vertical') {
                swiperOptions.autoHeight = true;
            }

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.1,
                centeredSlides: false,
                spaceBetween: 4,
                autoplay: false,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3,
                centeredSlides: true,
                spaceBetween: 14,
                autoplay: false,
            };
        }

        if (swiper.classList.contains('swiper--review')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 3;
            swiperOptions.loop = false;
            swiperOptions.navigation = false;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.2
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3
            };
        }


        if (swiper.classList.contains('swiper--logos')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 3;
            swiperOptions.loop = true;
            swiperOptions.autoplay = {
                delay: 1,
            }
            swiperOptions.speed = 10000;
            swiperOptions.navigation = false;
            swiperOptions.watchOverflow = true;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 2.5
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                spaceBetween: 38,
                enabled: false,
            };
        }

        if (swiper.classList.contains('swiper--steps')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 5;
            swiperOptions.loop = false;
            swiperOptions.navigation = false;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 2.5
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 5
            };
        }

        if (swiper.classList.contains('swiper--image')) {
            swiperOptions.autoHeight = swiper.attributes.getNamedItem("data-swiper-height")?.value === 'true' ? true : false;
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.1,
                centeredSlides: true,
            };
        }

        if (swiper.classList.contains('swiper--full')) {
            swiperOptions.autoHeight = swiper.attributes.getNamedItem("data-swiper-height")?.value === 'true' ? true : false;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.2,
                centeredSlides: true,
                spaceBetween: 20,
                autoplay: false,
                speed: 1000,
                parallax: true,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 0.9,
                centeredSlides: true,
                spaceBetween: 40,
                autoplay: false,
                speed: 1000,
                parallax: true,
            };
        }


        // =-=-=-=-=-=-=-=-=-=-=-=- End default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // If you want to overwrite a swiper carousel do it between the "Begin overwrite swiper carousels" and "End overwrite swiper carousels"

        if (swiper.classList.contains('swiper--hero')) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 1;

            // Overrides the default breakpoint options with nothing, so the above options are used
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
            swiperOptions.breakpoints[`${breakpoints.lg}`] = {};
        }

        if (swiper.classList.contains('swiper--logo')) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;
            swiperOptions.slidesPerView = 6;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
        }

        if (swiper.classList.contains('swiper--gallery')) {

            swiperOptions.spaceBetween = 14;
            swiperOptions.watchSlidesProgress = false;
            swiperOptions.autoHeight = false;
            swiperOptions.centeredSlides = false,
                swiperOptions.grabCursor = false;
            swiperOptions.allowTouchMove = false;
            swiperOptions.autoplay = false;

            console.log(document.querySelector('.swiper--gallery-thumbs')?.swiper);

            swiperOptions.thumbs = {
                swiper: document.querySelector('.swiper--gallery-thumbs')?.swiper,
            }

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 1,
                grabCursor: false,
                autoplay: false,
            };

        }


        if (swiper.classList.contains("swiper--projecten")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.06,
                spaceBetween: 20,
                loop: false,
            };
            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3,
                spaceBetween: 40,
                loop: false,
            };

            swiperOptions.navigation = {
                nextEl: swiper
                    .closest(".pane")
                    .querySelector(".swiper__button--next"),
                prevEl: swiper
                    .closest(".pane")
                    .querySelector(".swiper__button--prev"),
                disabledClass: "swiper__button--disabled",
            };
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-

        const swiperDefault = new Swiper(swiper, swiperOptions)

    });
})();
let swiperThumbs;
let swiper2;

const initSwipers = () => {
    // Swiper Thumbs Initialisatie
    swiperThumbs = new Swiper(".swiper--gallery-thumbs", {
        spaceBetween: 18,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
        slideClass: 'swiper__slide',
        direction: document.querySelector('.swiper--gallery-thumbs')?.getAttribute('data-swiper-direction') || 'horizontal',
        autoHeight: true,
        lazy: {
            loadPrevNext: true,
        },
    });

    // Swiper Gallery Initialisatie
    swiper2 = new Swiper(".swiper--gallery", {
        spaceBetween: 24,
        slideClass: 'swiper__slide',
        allowTouchMove: true,
        direction: 'horizontal',
        slidesPerView: 1.2,
        spaceBetween: 3,
        lazy: {
            loadPrevNext: true,
        },
    });
};

const destroySwipers = () => {
    if (swiperThumbs) {
        swiperThumbs.destroy(true, true); // swiperThumbs vernietigen
        swiperThumbs = null;
    }
    if (swiper2) {
        swiper2.destroy(true, true); // swiper2 vernietigen
        swiper2 = null;
    }
};

const checkSwipers = () => {
    const screenWidth = window.innerWidth;

    // Vernietig de Swiper als het scherm groter is dan 1024px
    if (screenWidth >= 1024) {
        destroySwipers();
    }
    // Herinitialiseer de Swiper als het scherm kleiner is dan 1024px
    else if (screenWidth < 1024) {
        if (!swiperThumbs && !swiper2) {
            initSwipers();
        }
    }
};

// Controleer bij het laden van de pagina
checkSwipers();

// Controleer op schermresolutieveranderingen
window.addEventListener('resize', checkSwipers);