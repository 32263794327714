class HeaderAnimation {
  constructor(header) {
    this.headerEle = document.querySelector(header);
    this.prevScrollPosition = window.pageYOffset;
    this.count = 0;
    this.state = "down";
    this.matchMedia = "(max-width: 700px)";

    if (!this.headerEle || document.body.classList.contains("wp-admin")) {
      return;
    }

    this.init();
  }

  init() {
    this.mediaQuery();
  }

  mediaQuery() {
    if (window.matchMedia(this.matchMedia).matches) {
      this.destroy();
    } else {
      this.active();
    }

    window.matchMedia(this.matchMedia).addEventListener("change", (event) => {
      if (event.matches) {
        this.destroy();
      } else {
        this.active();
      }
    });
  }

  scrollDirection = () => {
    if (this.count === 0) {
      this.prevScrollPosition = window.pageYOffset;
      this.count++;
    } else {
      this.count = 0;
      if (window.pageYOffset >= this.prevScrollPosition && this.prevScrollPosition > 0) {
        this.state = "up";
      } else {
        this.state = "down";
      }
    }
    // Change the global state after scrollDirection is changed
    this.changeState();
  }

  changeState(state = this.state) {
    if (state === "up") {
      this.headerEle.classList.add("is-hidden--mobile");
    } else if (state === "down") {
      this.headerEle.classList.remove("is-hidden--mobile");
    }
  }

  // Destroy current instance
  destroy() {
    window.removeEventListener("scroll", this.scrollDirection);
  }

  // Activate current instance
  active() {
    window.addEventListener("scroll", this.scrollDirection);
  }
}

// Create new instance with constructor function
const mainHeader = new HeaderAnimation("[data-button-header]");

// Initialize headerAnimation
if (!document.body.classList.contains("wp-admin")) {
  mainHeader.init();
}
