class Tabs {
    constructor(tabs, options){
        if(!tabs ){
            return;
        }

        this.tabNav = tabs;
        this.options = options || {};
        this.tabItems = [...tabs.querySelectorAll('[data-tab-target]')];
        this.activeState = 'is-active';
        this.defaultActiveTab = "openTab" in this.options ? this.options.openTab : this.tabItems[0].getAttribute('data-tab-target');
        this.init();
    }

    init(){
        // Remove active classes if exists
        this.closeTabs();
        this.setActiveTab(this.defaultActiveTab);
        this.clickEvent();
    }

    // Get content block el corresponding with navItem
    getContentBlock(value){
        return document.querySelector(`[data-tab-content="${value}"]`);
    }

    setActiveTab(value){
        document.querySelector(`[data-tab-target="${value}"]`).classList.add(this.activeState);
        document.querySelector(`[data-tab-content="${value}"]`).classList.add(this.activeState);
    }

    currentActiveTab(){
        return this.tabNav.querySelector(`.${this.activeState}`).getAttribute('data-tab-target');
    }

    removeActiveTab(value){
        document.querySelector(`[data-tab-target="${value}"]`).classList.remove(this.activeState);
        document.querySelector(`[data-tab-content="${value}"]`).classList.remove(this.activeState);
    }

    closeTabs(){
        this.tabItems.forEach(navItem => {
            const attrValue = navItem.getAttribute('data-tab-target');
            this.removeActiveTab(attrValue);
        });
    }

    clickEvent(){
        this.tabNav.addEventListener('click', (e) => {
            if(e.target.getAttribute('data-tab-target')){
                this.removeActiveTab(this.currentActiveTab());
                this.setActiveTab(e.target.getAttribute('data-tab-target'));
            }
        });
    }
}

if(document.querySelectorAll('[data-tab="tabs"]')){
    document.querySelectorAll('[data-tab="tabs"]').forEach(el => new Tabs(el));
}

function updateHeroTabsPosition() {
    // Check if the .hero__tabs element exists
    const heroTabs = document.querySelector('.hero__tabs');
    if (heroTabs) {
        // Get the height of the .tabs__title element
        const tabsTitle = document.querySelector('.tabs__title');
        if (tabsTitle) {
            const tabsTitleHeight = tabsTitle.offsetHeight;

            // Get the padding of the .hero element
            const hero = document.querySelector('.hero--extra');
            if (hero) {
                const heroPadding = window.getComputedStyle(hero).paddingTop;

                // Convert the padding value to a number (removing 'px')
                const heroPaddingValue = parseFloat(heroPadding);

                const translateValue = heroPaddingValue + tabsTitleHeight;
                // Calculate the translateY value
                const translateYValue = `-${translateValue}px`;

                // Apply the top style to the .hero__tabs element for screens wider than 992px
                if (window.innerWidth > 991) {
                    heroTabs.style.top = translateYValue;
                } else {
                    // Reset the top value for smaller screens
                    heroTabs.style.marginTop = `-${heroPaddingValue + 48}px`;
                }
            }
        }
    }
}

// Update position on DOMContentLoaded
document.addEventListener('DOMContentLoaded', updateHeroTabsPosition);

// Update position on window resize
window.addEventListener('resize', updateHeroTabsPosition);
