const Macy = require('Macy');

class MacyContainer {
    constructor(container, options) {
        this.container = container;
        this.options = options;
        this.targets = document.querySelectorAll(this.container);
        this.macyInstances = [];
        this.init();
    }

    init() {
        if (this.targets.length === 0) {
            return;
        }
        if (window.innerWidth >= 992) {
            this.createInstances();
        }
        this.setupResizeListener();
    }

    createInstances() {
        this.macyInstances = Array.from(this.targets).map((target) => {
            return Macy({
                ...this.options,
                container: target,
            });
        });
    }

    removeInstances() {
        if (this.macyInstances) {
            this.macyInstances.forEach((instance) => {
                instance.remove();
            });
            this.macyInstances = [];
        }
    }

    setupResizeListener() {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 992) {
                this.removeInstances();
            } else {
                if (this.macyInstances.length === 0) {
                    this.createInstances();
                }
            }
        });
    }
}

class ArchiveToggle {
    constructor(options, macyContainers) {
        this.buttons = document.querySelectorAll("[data-toggle-display]");
        this.elements = document.querySelectorAll("[data-toggle-display-element]");
        this.activeClass = "is-active";
        this.toggleValues = options?.toggle || {
            grid: ["col-md-6"],
            list: ["col-12", "col-md-10", "offset-md-1"],
        };
        this.default = options?.default || "grid";
        this.cards = options?.cards || null;
        this.activeFilter = this.default;
        this.macyContainers = macyContainers; // Store MacyContainer instances

        if (!this.buttons || !this.elements || document.body.classList.contains("wp-admin")) {
            return;
        }

        this.init();
    }

    reinit() {
        this.elements = document.querySelectorAll("[data-toggle-display-element]");
        this.toggle(this.activeFilter);
        this.cardsToggle(this.activeFilter);
        this.buttonEvents();
    }

    init() {
        this.toggle(this.default);
        this.cardsToggle(this.default);
        this.buttonEvents();
    }

    buttonEvents() {
        for (const button of this.buttons) {
            button.addEventListener("click", (e) => {
                this.setActive(e);
            });
        }
    }

    setActive(e) {
        const display = e.target.getAttribute("data-toggle-display");
        this.toggle(display);
    }

    cardsToggle(display) {
        if (!this.cards) {
            return;
        }

        const cards = document.querySelectorAll(this.cards.elements);

        for (const card of cards) {
            const displayKeys = Object.keys(this.cards.type);
            const removeClasses = displayKeys
                .map(key => this.cards.type[key])
                .flat();

            const addClasses = this.cards.type[display];

            card.classList.remove(...removeClasses);
            card.classList.add(...addClasses);
        }

        if (display === "grid") {
            this.macyContainers.forEach(container => {
                if (window.innerWidth >= 992) {
                    container.createInstances();
                }
            });
        } else if (display === "list") {
            this.macyContainers.forEach(container => container.removeInstances());
        }
    }

    toggle(display) {
        for (const element of this.elements) {
            const removeClasses = Object.values(this.toggleValues).flat();
            const addClasses = this.toggleValues[display];

            element.classList.remove(...removeClasses);
            element.classList.add(...addClasses);
        }

        for (const button of this.buttons) {
            if (button.getAttribute("data-toggle-display") === display) {
                button.classList.add(this.activeClass);
            } else {
                button.classList.remove(this.activeClass);
            }
        }

        this.activeFilter = display;
        this.cardsToggle(display);
    }
}

// Macy options
const macyOptions = {
    trueOrder: false,
    waitForImages: false,
    margin: 80,
    columns: 1,
    mobileFirst: true,
    breakAt: {
        992: {
            columns: 2,
        },
        520: {
            columns: 1,
        },
        400: {
            columns: 1,
        },
    },
};

// Container selectors for Macy
const containerSelectors = ['.project-grid'];

// Create MacyContainer instances
const macyContainers = containerSelectors.map(selector => new MacyContainer(selector, macyOptions));

// Options for ArchiveToggle
const options = {
    default: "grid",
    toggle: {
        grid: ["col-md-6"],
        list: ["col-12", "col-md-10", "offset-md-1"],
    },
    cards: {
        elements: ".card--project",
        type: {
            grid: ["card--project-grid"],
            list: ["card--project-list"],
        },
    },
};

// Instantiate ArchiveToggle with MacyContainer instances
const archivetoggle = new ArchiveToggle(options, macyContainers);

// Reinitialize ArchiveToggle on AJAX load
document.addEventListener("facetwp-loaded", function () {
    archivetoggle.reinit();
});
