class hamburgerMenu {
    constructor(iconToggle, menuContainer) {
        this.iconToggle = iconToggle;           // menu icon
        this.menuContainer = menuContainer;     // menu nav container
        this.hamburger = document.querySelector('.hamburger');

        if (this.iconToggle && this.menuContainer) {  // check if contains elements
            this.clickEvent();
        } else {
            return;
        }
    }

    clickEvent() {
        this.iconToggle.addEventListener("click", () => {
            this.menuToggle();
        });
    }

    menuToggle() {
        this.removeMenuSectionStates();
        this.iconToggle.classList.toggle("hamburger-menu--active");
        this.menuContainer.classList.toggle("is-active");
        this.hamburger.classList.toggle("is-active");

        this.menuSectionListener();
    }

    removeMenuSectionStates() {
        this.menuContainer.querySelectorAll('[data-menu-section]').forEach((section) => {
            section.classList.remove('is-active');
        });
    }

    menuSectionListener() {
        this.menuContainer.addEventListener('click', this.sectionToggle);
    }

    sectionToggle(e) {
        const menuItemAttr = 'data-menu-toggle';
        const menuReturnButton = 'data-menu-return';

        if (e.target.classList.contains('icon--dropdown')) {
            if (e.target.parentNode.querySelector('[data-menu-section]')) {
                e.target.parentNode.querySelector('[data-menu-section]').classList.add('is-active');
            }
        }

        if (e.target.classList.contains('btn-return') || e.target.parentNode.classList.contains('btn-return')) {
            e.target.closest('[data-menu-section]').classList.remove('is-active');
        }
    }
}

const mobileMenu = new hamburgerMenu(document.querySelector('[data-menu-icon]'), document.querySelector('[data-menu-container]'));