function vimeoHero(iframe) {
  const vimeoIframe = document.querySelector(iframe);
  const vimeoContainer = vimeoIframe?.closest("[data-vimeo-container]");
  const playButton = vimeoContainer?.querySelector(
    '[data-video-button="play"]'
  );
  const pauseButton = vimeoContainer?.querySelector(
    '[data-video-button="pause"]'
  );
  const hiddenClass = "is-hidden";

  if (!vimeoIframe) {
    return;
  }

  const player = new Vimeo.Player(vimeoIframe);

  async function initPlayer() {
    try {
      await player.setVolume(0);
      await player.play();
      console.log('Playback started');

      player.on("play", () => {
        playButton.classList.add(hiddenClass);
      });

      player.on("pause", () => {
        pauseButton.classList.add(hiddenClass);
      });

      buttonEvents(playButton, pauseButton);
      setupIntersectionObserver();
    } catch (error) {
      console.error('Error during player initialization:', error);
    }
  }

  initPlayer();

  async function playerVolume() {
    try {
      const volume = await player.getVolume();
      return volume;
    } catch (error) {
      console.log('error');
      console.error('Error getting volume:', error);
      return null;
    }
  }

  async function checkVolume() {
    const volume = await playerVolume();
    return volume;
  }

  async function setAudioSettings() {
    try {
      await player.setMuted(false);
      await player.setVolume(1);
      console.log('Player unmuted and volume set to 100%');
    } catch (error) {
      console.error('Error during audio setup:', error);
    }
  }

  function buttonEvents(...buttons) {
    buttons.forEach((button) => {
      button.addEventListener("click", async () => {
        let buttonState = button.getAttribute("data-video-button");
        const volume = await checkVolume();

        if (buttonState === "play") {
          if (document.hasFocus()) {
            if (volume === 0) {
              await setAudioSettings();
            }
            setTimeout(async () => {
              await player.play().catch(error => {
                console.error('Error playing video:', error);
              });
            }, 200);
          }
        } else if (buttonState === "pause") {
          await player.pause();
          playButton.classList.remove(hiddenClass);
        }
      });
    });
  }

  function setupIntersectionObserver() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
        } else {
          player.pause();
        }
      });
    }, {
      threshold: 0.1
    });

    observer.observe(vimeoIframe);
  }
}

vimeoHero(".hero iframe");