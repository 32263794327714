function removeTagNameFromEditor(charToRemove) {
    const editorElements = document.querySelectorAll(".editor");

    if (!editorElements) {
        return;
    }

    editorElements.forEach(editor => {
        // Call recursive loop for editor DOM element, and the characters to remove
        recurse(editor, charToRemove);
    });
}

// Get and remove all 'br's / chars
function removeCharachter(char, el) {
    const chars = el.querySelectorAll(char);

    if (chars) {
        chars.forEach(char => {
            char.remove();
        });
    }
}

function recurse(el, charToRemove) {
    let editorChildren = [...el.children];

    // Filter array for p tags, return those that are not P tags
    editorChildren = editorChildren.filter((child) => {
        return child.tagName !== "P";
    });

    // Loop recursive through each parent > child
    if (editorChildren) {
        for (let i = 0; i < editorChildren.length;) {
            removeCharachter(charToRemove, editorChildren[i]);

            // Call same function again with child of parent
            recurse(editorChildren[i]);
            i++;
        }
    }
}

// Wait untill content is ready
window.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth < 920) {
        removeTagNameFromEditor('br');
    }
});
