// class ArchiveToggle {
//     constructor(options) {
//         this.buttons = document.querySelectorAll("[data-toggle-display]");
//         this.elements = document.querySelectorAll(
//             "[data-toggle-display-element]"
//         );

//         this.activeClass = "is-active";

//         this.toggleValues = options?.toggle || {
//             grid: "col-md-6",
//             list: "col-12",
//         };
//         this.default = options?.default || "grid";
//         this.cards = options?.cards || null;
//         this.activeFilter = this.default;

//         if (
//             !this.buttons ||
//             !this.elements ||
//             document.body.classList.contains("wp-admin")
//         ) {
//             return;
//         }
//     }

//     reinit() {
//         this.elements = document.querySelectorAll(
//             "[data-toggle-display-element]"
//         );
//         this.toggle(this.activeFilter);
//         this.cardsToggle(this.activeFilter);
//         this.buttonEvents();
//     }

//     init() {
//         this.toggle(this.default);
//         this.cardsToggle(this.default);
//         this.buttonEvents();
//     }

//     buttonEvents() {
//         for (const button of this.buttons) {
//             button.addEventListener("click", (e) => {
//                 this.setActive(e);
//             });
//         }
//     }

//     setActive(e) {
//         const display = e.target.getAttribute("data-toggle-display");
//         this.toggle(display);
//     }

//     cardsToggle(display) {
//         if (!this.cards) {
//             return;
//         }

//         const cards = document.querySelectorAll(this.cards.elements);

//         for (const card of cards) {
//             if (display === Object.keys(this.cards.type)[0]) {
//                 card.classList.remove(Object.values(this.cards.type)[1]);
//                 card.classList.add(Object.values(this.cards.type)[0]);
//             } else if (display === Object.keys(this.cards.type)[1]) {
//                 card.classList.remove(Object.values(this.cards.type)[0]);
//                 card.classList.add(Object.values(this.cards.type)[1]);
//             }
//         }
//     }

//     toggle(display) {
//         for (const element of this.elements) {
//             element.removeAttribute("class");
//             element.classList.add(this.toggleValues[`${display}`]);
//             this.cardsToggle(display);
//         }

//         for (const button of this.buttons) {
//             if (button.getAttribute("data-toggle-display") === display) {
//                 button.classList.add(this.activeClass);
//             } else {
//                 button.classList.remove(this.activeClass);
//             }
//         }

//         this.activeFilter = display;
//     }
// }

// const options = {
//     default: "grid",
//     toggle: {
//         grid: "col-md-6",
//         list: "col-12",
//     },
//     cards: {
//         elements: ".card--project",
//         type: {
//             grid: "card--project-grid",
//             list: "card--project-list",
//         },
//     },
// };

// // Runs after the refresh process, on each AJAX page load, and on the initial page load.
// const archivetoggle = new ArchiveToggle(options);

// document.addEventListener("facetwp-loaded", function () {
//     archivetoggle.reinit();
// });
